// import { LogLevel } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    // clientId: "8707016d-bdaf-497e-ad77-acefe1377b5f",
    clientId: "c7e0407e-0f32-4191-b20f-3a39cd1c275c",
    authority:
      // "https://login.microsoftonline.com/9c70cb11-89d2-4170-bd36-2e87c3024dbd",
      "https://login.microsoftonline.com/78181095-efa1-4806-9626-ca6be188d1d0",
    redirectUri: "https://bancoguayaquil.jelou.ai",
    // redirectUri: "http://localhost:3000/",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // switch (level) {
        //     case LogLevel.Error:
        //         console.error(message);
        //         return;
        //     case LogLevel.Info:
        //         console.info(message);
        //         return;
        //     case LogLevel.Verbose:
        //         console.debug(message);
        //         return;
        //     case LogLevel.Warning:
        //         console.warn(message);
        //         return;
        // }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

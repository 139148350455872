import React from "react";

import Icon from "./Icon";

const BGLogoIcon = (props) => (
  <Icon
    viewBox="0 0 825.37 361.19"
    className={props.className}
    width={props.width}
    height={props.height}
  >
    <g>
      <g>
        <path
          fill="#D20F8C"
          d="M516.62,280.34c0,7.49-5.47,12.47-14.64,12.47h-5.92v10.99h-7.84v-36.09h13.8
			C510.95,267.72,516.62,272.94,516.62,280.34z M508.68,280.34c0-3.35-2.17-5.87-6.85-5.87h-5.77v11.59h5.77
			C506.51,286.06,508.68,283.69,508.68,280.34z"
        />
        <path
          fill="#D20F8C"
          d="M539.39,275.55v7.94h-0.89c-4.78,0-9.42,2.12-9.42,9.56v10.75h-7.49v-27.95h7.49v5.62
			C530.77,278.71,533.73,275.55,539.39,275.55z"
        />
        <path
          fill="#D20F8C"
          d="M548.32,272.94c-2.42,0-4.34-1.87-4.34-4.14c0-2.17,1.97-4.04,4.34-4.04c2.32,0,4.24,1.77,4.24,4.04
			C552.56,271.07,550.68,272.94,548.32,272.94z M544.52,275.85h7.49v27.95h-7.49V275.85z"
        />
        <path
          fill="#D20F8C"
          d="M604.42,286.99v16.81h-7.49v-15.83c0-3.65-2.27-5.87-5.57-5.87c-3.16,0-5.77,2.56-5.77,6.41v15.28h-7.49
			v-15.73c0-3.75-2.22-5.97-5.42-5.97c-3.45,0-5.92,3.35-5.92,6.51v15.18h-7.49v-27.95h7.49v3.94c2.02-2.22,4.93-4.44,8.87-4.44
			c3.85,0,6.9,1.92,8.58,5.18c2.07-2.37,5.32-5.18,10.16-5.18C600.33,275.36,604.42,279.79,604.42,286.99z"
        />
        <path
          fill="#D20F8C"
          d="M609.35,289.85c0-9.81,7.59-14.54,14.35-14.54c8.33,0,14.3,5.32,14.3,15.14c0,0.89-0.05,1.73-0.15,2.46
			h-20.85c0.69,2.66,3.25,5.28,8.43,5.28c4.24,0,6.95-2.51,6.95-2.51l3.99,3.8c0,0-3.75,4.88-11.39,4.88
			C615.17,304.35,609.35,298.63,609.35,289.85z M616.94,287.93h13.31c-0.05-3.06-2.22-6.16-6.56-6.16
			C619.41,281.77,617.04,284.87,616.94,287.93z"
        />
        <path
          fill="#D20F8C"
          d="M661.07,275.55v7.94h-0.89c-4.78,0-9.42,2.12-9.42,9.56v10.75h-7.49v-27.95h7.49v5.62
			C652.44,278.71,655.4,275.55,661.07,275.55z"
        />
        <path
          fill="#D20F8C"
          d="M663.83,289.8c0-7.99,6.21-14.49,14.94-14.49c8.73,0,14.94,6.51,14.94,14.49c0,7.99-6.21,14.54-14.94,14.54
			C670.04,304.35,663.83,297.79,663.83,289.8z M671.37,289.8c0,4.39,2.81,7.84,7.39,7.84c4.63,0,7.39-3.45,7.39-7.84
			c0-4.44-2.76-7.79-7.39-7.79C674.18,282.01,671.37,285.37,671.37,289.8z"
        />
        <path
          fill="#D20F8C"
          d="M722.25,294.63c0,2.56,1.58,3.4,3.4,3.4c1.48,0,2.66-0.49,2.66-0.49l1.18,5.62c0,0-2.12,1.18-6.31,1.18
			c-5.28,0-8.43-2.51-8.43-7.4v-14.4h-3.01v-2.81l10.11-10.16h0.4v6.26h6.01v6.7h-6.01V294.63z"
        />
        <path
          fill="#D20F8C"
          d="M761.64,275.85v27.95h-7.49v-4.19c-2.27,2.32-5.52,4.73-9.81,4.73c-6.11,0-10.35-4.49-10.35-11.98v-16.52
			h7.49v15.38c0,4.09,2.42,6.36,5.96,6.36c3.85,0,6.71-2.66,6.71-6.41v-15.33H761.64z M747.93,272.84h-5.52l5.67-7.49l6.31,2.22
			L747.93,272.84z"
        />
      </g>
      <g>
        <path
          fill="#D20F8C"
          d="M153.51,56.85c-18.34,0-35.74,5.4-50.48,15.48c0,0-1.13,0.75-2.93,2.09c-0.17,0.13-0.35,0.26-0.52,0.4
			c-0.68,0.52-1.42,1.1-2.24,1.75c-0.15,0.12-0.3,0.23-0.45,0.35c0.01-0.01,0.02-0.01,0.04-0.01c-4.1,3.33-9.51,8.25-13.85,14.02
			h0.01c-12.53,15.81-19.37,35.29-19.37,55.69c0,23.11,8.75,45.04,24.65,61.79c0.34,0.21,0.69,0.4,1.03,0.6
			c0.34,0.2,0.67,0.4,1.01,0.59c0.38,0.21,0.75,0.41,1.13,0.61c0.34,0.19,0.69,0.38,1.03,0.56c0.38,0.2,0.76,0.38,1.14,0.57
			c0.35,0.17,0.71,0.35,1.06,0.52c0.38,0.18,0.77,0.35,1.16,0.52c0.36,0.16,0.71,0.33,1.08,0.49c0.39,0.16,0.78,0.32,1.17,0.48
			c0.37,0.15,0.73,0.31,1.1,0.45c0.39,0.15,0.79,0.3,1.18,0.45c0.37,0.14,0.73,0.29,1.11,0.41c0.4,0.14,0.8,0.26,1.2,0.4
			c0.38,0.13,0.75,0.26,1.13,0.37c0.4,0.13,0.81,0.24,1.21,0.36c0.38,0.11,0.76,0.23,1.14,0.34c0.41,0.11,0.82,0.22,1.24,0.32
			c0.38,0.1,0.75,0.2,1.13,0.29c0.41,0.11,0.84,0.19,1.25,0.29c0.38,0.08,0.76,0.18,1.14,0.25c0.42,0.09,0.85,0.16,1.27,0.24
			c0.38,0.07,0.75,0.15,1.14,0.22c0.43,0.07,0.87,0.13,1.31,0.2c0.37,0.06,0.75,0.12,1.12,0.17c0.46,0.06,0.91,0.11,1.36,0.16
			c0.37,0.04,0.72,0.09,1.08,0.13c0.48,0.04,0.97,0.08,1.44,0.12c0.34,0.02,0.68,0.06,1.02,0.08c0.56,0.04,1.12,0.06,1.67,0.08
			c0.26,0.02,0.53,0.03,0.79,0.04c0.83,0.03,1.65,0.04,2.48,0.04c9.4,0,18.54-1.79,27.19-5.3c9.58-3.9,18.15-9.78,25.22-17.25
			c2.35-2.48,4.54-5.15,6.53-7.97c0.49-0.68,0.96-1.38,1.42-2.08c0.15-0.2,0.27-0.42,0.41-0.63c0.34-0.52,0.67-1.04,0.98-1.57
			c0.12-0.21,0.26-0.42,0.38-0.63c0.42-0.71,0.83-1.42,1.23-2.14c0.04-0.08,0.09-0.17,0.13-0.26c0.36-0.65,0.7-1.31,1.03-1.96
			c0.11-0.23,0.22-0.45,0.33-0.66c0.29-0.58,0.56-1.17,0.83-1.75c0.07-0.18,0.17-0.36,0.24-0.54c0.33-0.72,0.64-1.45,0.94-2.18
			c-1.74,2.48-3.73,4.82-5.95,6.97c-8.94,8.63-20.7,13.39-33.12,13.39c-12.43,0-24.19-4.76-33.12-13.39
			c-8.92-8.62-14.06-20.16-14.49-32.53c-0.01-0.4-0.01-0.78-0.01-1.16l0.01-0.54c0-0.56,0-1.12,0.02-1.68h-0.02
			c0.07-3.34,0.38-6.65,0.88-9.91c0-0.02,0.01-0.05,0.01-0.07c0.13-0.84,0.27-1.67,0.42-2.5c0.04-0.17,0.07-0.34,0.11-0.5
			c0.12-0.64,0.26-1.29,0.41-1.93c0.07-0.33,0.15-0.67,0.22-1c0.1-0.39,0.19-0.79,0.29-1.18c0.15-0.56,0.3-1.12,0.45-1.68
			c0.09-0.3,0.17-0.6,0.26-0.9c0.19-0.63,0.39-1.26,0.59-1.89c0.07-0.24,0.16-0.48,0.24-0.71c0.22-0.68,0.46-1.35,0.71-2.01
			c0.07-0.19,0.14-0.38,0.21-0.56c0.26-0.71,0.54-1.4,0.83-2.11c0.06-0.15,0.12-0.3,0.19-0.45c0.3-0.72,0.62-1.44,0.94-2.16
			c0.06-0.12,0.11-0.24,0.16-0.35c0.34-0.74,0.7-1.48,1.07-2.21l0.14-0.28c0.38-0.75,0.77-1.49,1.18-2.22
			c0.04-0.08,0.08-0.15,0.13-0.23c0.42-0.75,0.84-1.49,1.28-2.22c0.04-0.06,0.07-0.11,0.11-0.17c0.45-0.75,0.92-1.5,1.41-2.23
			c0.02-0.04,0.04-0.07,0.07-0.1c0.49-0.76,1.01-1.51,1.54-2.26c0.5-0.71,1.01-1.4,1.54-2.09c0.17-0.23,0.34-0.45,0.52-0.67
			c0.36-0.46,0.71-0.92,1.08-1.36c0.22-0.26,0.44-0.52,0.65-0.78c0.33-0.4,0.67-0.8,1.01-1.2c0.24-0.27,0.48-0.53,0.72-0.79
			c0.34-0.38,0.67-0.75,1.02-1.12c0.25-0.26,0.5-0.53,0.75-0.79c0.34-0.36,0.69-0.71,1.04-1.06c0.26-0.26,0.52-0.51,0.78-0.76
			c0.36-0.35,0.72-0.69,1.09-1.03c0.26-0.25,0.52-0.49,0.79-0.73c0.38-0.34,0.76-0.68,1.15-1.01c0.26-0.23,0.52-0.46,0.78-0.68
			c0.41-0.34,0.83-0.68,1.24-1.02c0.24-0.2,0.5-0.41,0.74-0.6c0.48-0.38,0.96-0.74,1.45-1.11c0.19-0.14,0.39-0.3,0.58-0.44
			c0.69-0.51,1.39-1.01,2.09-1.49c0.1-0.06,0.19-0.12,0.28-0.19c0.61-0.41,1.23-0.83,1.86-1.22c0.24-0.15,0.5-0.31,0.75-0.46
			c0.48-0.3,0.95-0.59,1.44-0.87c0.29-0.17,0.58-0.34,0.88-0.51c0.12-0.07,0.24-0.14,0.36-0.2c2.18-1.21,7.07-3.75,13.03-5.8
			c0.05-0.01,0.09-0.03,0.14-0.05c0.27-0.1,0.55-0.18,0.83-0.27c0.29-0.1,0.59-0.19,0.89-0.28c0.27-0.08,0.54-0.17,0.81-0.26
			c0.31-0.08,0.61-0.17,0.92-0.26c0.27-0.08,0.54-0.15,0.81-0.23c0.3-0.08,0.61-0.16,0.92-0.24c0.27-0.07,0.54-0.14,0.81-0.21
			c0.3-0.07,0.6-0.14,0.89-0.21c0.28-0.06,0.57-0.13,0.85-0.19c0.31-0.07,0.63-0.14,0.95-0.2c0.27-0.05,0.54-0.12,0.81-0.16
			c0.32-0.06,0.65-0.12,0.96-0.18c0.27-0.05,0.53-0.1,0.81-0.15c0.32-0.05,0.64-0.1,0.98-0.15c0.26-0.04,0.53-0.08,0.79-0.12
			c0.33-0.04,0.66-0.09,0.99-0.13c0.26-0.04,0.53-0.07,0.79-0.11c0.34-0.04,0.68-0.07,1.01-0.11c0.26-0.03,0.52-0.06,0.78-0.09
			c0.35-0.03,0.7-0.06,1.05-0.08c0.25-0.02,0.5-0.05,0.76-0.06c0.37-0.03,0.73-0.05,1.09-0.07c0.24-0.02,0.47-0.03,0.71-0.04
			c0.41-0.02,0.81-0.04,1.22-0.04c0.19-0.01,0.39-0.02,0.58-0.03c0.61-0.01,1.22-0.01,1.82-0.01c0.77,0,1.55,0.01,2.32,0.04
			c0.22,0.01,0.43,0.01,0.65,0.02c0.61,0.03,1.22,0.06,1.83,0.09c0.18,0.01,0.36,0.02,0.53,0.03c0.76,0.06,1.53,0.12,2.29,0.2
			c0.16,0.02,0.33,0.04,0.49,0.05c0.62,0.07,1.23,0.14,1.84,0.22c0.22,0.03,0.44,0.06,0.65,0.09c0.72,0.1,1.43,0.21,2.14,0.33
			c0.04,0.01,0.09,0.01,0.14,0.02c0.76,0.13,1.51,0.28,2.26,0.43c0.2,0.04,0.41,0.09,0.61,0.13c0.59,0.13,1.18,0.27,1.77,0.4
			c0.18,0.05,0.36,0.08,0.54,0.13c0.75,0.18,1.47,0.38,2.21,0.58c0.14,0.04,0.28,0.08,0.42,0.12c0.61,0.17,1.21,0.35,1.81,0.54
			c0.21,0.07,0.41,0.13,0.62,0.2c0.65,0.21,1.29,0.42,1.93,0.65c0.09,0.03,0.17,0.06,0.26,0.08c0.72,0.26,1.42,0.52,2.13,0.8
			c0.2,0.08,0.4,0.16,0.59,0.24c0.27,0.1,0.53,0.2,0.79,0.31C194.18,63.9,174.46,56.85,153.51,56.85"
        />
        <path
          fill="#D20F8C"
          d="M156.19,238.42c18.34,0,35.74-5.4,50.48-15.48h0c0,0,1.13-0.75,2.93-2.09c0.18-0.13,0.35-0.26,0.52-0.4
			c0.68-0.52,1.42-1.09,2.24-1.75c0.15-0.12,0.3-0.23,0.45-0.35c-0.01,0.01-0.02,0.01-0.04,0.01c4.1-3.33,9.51-8.25,13.85-14.02
			h-0.01c12.53-15.81,19.37-35.29,19.37-55.69c0-23.11-8.75-45.04-24.65-61.79c-0.34-0.21-0.69-0.4-1.03-0.6
			c-0.33-0.2-0.67-0.4-1.01-0.59c-0.38-0.21-0.76-0.41-1.13-0.61c-0.34-0.19-0.69-0.38-1.03-0.56c-0.38-0.2-0.76-0.38-1.14-0.57
			c-0.35-0.17-0.71-0.35-1.06-0.52c-0.38-0.18-0.77-0.35-1.16-0.53c-0.36-0.16-0.72-0.33-1.08-0.49c-0.39-0.16-0.78-0.32-1.17-0.48
			c-0.37-0.15-0.73-0.31-1.1-0.45c-0.39-0.15-0.79-0.3-1.18-0.45c-0.37-0.14-0.73-0.29-1.11-0.41c-0.4-0.14-0.8-0.26-1.2-0.4
			c-0.38-0.13-0.75-0.26-1.13-0.38c-0.4-0.13-0.81-0.24-1.21-0.36c-0.38-0.11-0.76-0.23-1.14-0.34c-0.41-0.12-0.82-0.22-1.24-0.32
			c-0.38-0.1-0.75-0.2-1.13-0.29c-0.41-0.11-0.84-0.19-1.25-0.29c-0.38-0.08-0.76-0.18-1.14-0.25c-0.42-0.09-0.85-0.16-1.27-0.24
			c-0.38-0.08-0.76-0.15-1.14-0.22c-0.43-0.07-0.87-0.13-1.31-0.2c-0.37-0.06-0.75-0.12-1.12-0.17c-0.45-0.06-0.91-0.11-1.36-0.16
			c-0.37-0.04-0.72-0.09-1.08-0.13c-0.48-0.04-0.97-0.08-1.44-0.12c-0.34-0.02-0.68-0.06-1.02-0.08c-0.56-0.03-1.12-0.06-1.67-0.08
			c-0.27-0.02-0.53-0.03-0.79-0.04c-0.83-0.03-1.65-0.04-2.48-0.04c-9.4,0-18.54,1.79-27.19,5.3c-9.58,3.9-18.15,9.78-25.22,17.25
			c-2.35,2.48-4.54,5.15-6.53,7.98c-0.49,0.68-0.96,1.38-1.42,2.08c-0.15,0.2-0.27,0.42-0.41,0.63c-0.34,0.52-0.67,1.04-0.98,1.57
			c-0.12,0.21-0.26,0.42-0.38,0.63c-0.42,0.71-0.83,1.42-1.23,2.15c-0.04,0.08-0.09,0.17-0.13,0.26c-0.36,0.65-0.7,1.31-1.03,1.96
			c-0.11,0.22-0.22,0.45-0.33,0.66c-0.29,0.58-0.56,1.16-0.83,1.75c-0.08,0.18-0.17,0.36-0.24,0.54c-0.33,0.72-0.64,1.45-0.94,2.18
			c1.73-2.48,3.73-4.81,5.95-6.97c8.94-8.63,20.7-13.39,33.12-13.39c12.43,0,24.19,4.76,33.12,13.39
			c8.92,8.62,14.07,20.16,14.49,32.52c0.01,0.4,0,0.78,0,1.16l0,0.54c0,0.56,0,1.12-0.02,1.68h0.02c-0.07,3.34-0.38,6.65-0.88,9.91
			c0,0.02-0.01,0.05-0.01,0.07c-0.13,0.84-0.27,1.67-0.42,2.5c-0.04,0.17-0.07,0.34-0.11,0.5c-0.12,0.64-0.26,1.29-0.41,1.93
			c-0.07,0.33-0.15,0.67-0.22,1c-0.1,0.39-0.19,0.79-0.29,1.18c-0.15,0.56-0.3,1.12-0.45,1.68c-0.09,0.3-0.17,0.6-0.26,0.9
			c-0.19,0.63-0.39,1.26-0.59,1.89c-0.07,0.24-0.16,0.48-0.24,0.71c-0.22,0.68-0.46,1.35-0.71,2.01c-0.07,0.19-0.14,0.38-0.21,0.56
			c-0.26,0.71-0.54,1.4-0.82,2.11c-0.06,0.15-0.12,0.3-0.19,0.45c-0.3,0.72-0.62,1.44-0.94,2.16c-0.06,0.12-0.11,0.24-0.16,0.35
			c-0.34,0.74-0.7,1.48-1.07,2.21l-0.14,0.28c-0.38,0.75-0.77,1.49-1.18,2.22c-0.04,0.08-0.08,0.15-0.13,0.23
			c-0.42,0.75-0.84,1.49-1.28,2.22c-0.04,0.06-0.07,0.11-0.11,0.17c-0.45,0.75-0.92,1.5-1.41,2.23c-0.02,0.04-0.04,0.07-0.07,0.1
			c-0.49,0.76-1.01,1.51-1.54,2.26c-0.5,0.71-1.01,1.4-1.54,2.09c-0.17,0.23-0.34,0.45-0.52,0.67c-0.36,0.46-0.71,0.92-1.08,1.36
			c-0.22,0.26-0.44,0.52-0.65,0.78c-0.33,0.4-0.68,0.8-1.01,1.2c-0.24,0.27-0.48,0.53-0.72,0.79c-0.34,0.38-0.67,0.75-1.02,1.12
			c-0.25,0.27-0.5,0.53-0.75,0.79c-0.34,0.36-0.69,0.71-1.04,1.06c-0.26,0.26-0.52,0.51-0.78,0.76c-0.36,0.35-0.72,0.69-1.09,1.03
			c-0.26,0.25-0.52,0.49-0.79,0.73c-0.38,0.34-0.76,0.67-1.15,1.01c-0.26,0.23-0.52,0.46-0.78,0.68c-0.41,0.34-0.82,0.68-1.24,1.01
			c-0.24,0.2-0.49,0.41-0.74,0.6c-0.48,0.38-0.96,0.74-1.45,1.11c-0.19,0.14-0.39,0.3-0.58,0.44c-0.69,0.51-1.39,1.01-2.09,1.49
			c-0.1,0.06-0.19,0.12-0.28,0.19c-0.61,0.41-1.23,0.82-1.86,1.22c-0.24,0.15-0.5,0.31-0.75,0.46c-0.48,0.3-0.95,0.59-1.44,0.87
			c-0.29,0.17-0.58,0.34-0.88,0.51c-0.12,0.07-0.24,0.14-0.36,0.2c-2.18,1.21-7.07,3.75-13.03,5.8c-0.05,0.01-0.09,0.03-0.14,0.05
			c-0.27,0.1-0.55,0.18-0.83,0.27c-0.29,0.1-0.59,0.19-0.89,0.28c-0.27,0.08-0.54,0.17-0.81,0.26c-0.31,0.08-0.61,0.17-0.92,0.26
			c-0.27,0.07-0.54,0.15-0.81,0.22c-0.3,0.08-0.61,0.16-0.92,0.24c-0.26,0.07-0.54,0.14-0.81,0.21c-0.3,0.07-0.59,0.14-0.89,0.21
			c-0.28,0.06-0.57,0.13-0.85,0.19c-0.31,0.07-0.64,0.14-0.95,0.2c-0.27,0.05-0.54,0.12-0.81,0.16c-0.32,0.06-0.64,0.12-0.96,0.18
			c-0.27,0.05-0.53,0.1-0.81,0.15c-0.32,0.05-0.64,0.1-0.98,0.15c-0.26,0.04-0.53,0.08-0.79,0.12c-0.33,0.04-0.66,0.09-0.99,0.13
			c-0.26,0.04-0.53,0.08-0.79,0.11c-0.34,0.04-0.68,0.07-1.01,0.11c-0.26,0.03-0.52,0.06-0.78,0.09c-0.35,0.03-0.7,0.06-1.05,0.08
			c-0.25,0.02-0.5,0.05-0.76,0.06c-0.37,0.03-0.73,0.05-1.09,0.07c-0.24,0.02-0.47,0.03-0.71,0.04c-0.41,0.02-0.81,0.03-1.22,0.04
			c-0.19,0.01-0.39,0.02-0.58,0.03c-0.61,0.01-1.22,0.01-1.82,0.01c-0.77,0-1.55-0.01-2.32-0.03c-0.22-0.01-0.43-0.01-0.65-0.02
			c-0.61-0.03-1.22-0.06-1.83-0.09c-0.18-0.01-0.36-0.02-0.53-0.04c-0.76-0.06-1.53-0.12-2.29-0.2c-0.16-0.02-0.33-0.04-0.49-0.05
			c-0.62-0.07-1.23-0.14-1.84-0.22c-0.22-0.03-0.44-0.06-0.65-0.09c-0.72-0.1-1.44-0.21-2.15-0.33c-0.04-0.01-0.09-0.01-0.14-0.02
			c-0.76-0.13-1.5-0.28-2.26-0.43c-0.2-0.04-0.41-0.09-0.61-0.13c-0.59-0.13-1.18-0.26-1.77-0.4c-0.18-0.05-0.36-0.08-0.54-0.13
			c-0.75-0.18-1.47-0.38-2.21-0.58c-0.14-0.04-0.28-0.08-0.42-0.12c-0.61-0.17-1.21-0.35-1.81-0.54c-0.21-0.07-0.41-0.13-0.62-0.2
			c-0.65-0.21-1.29-0.42-1.93-0.65c-0.09-0.03-0.17-0.06-0.26-0.08c-0.71-0.26-1.42-0.52-2.13-0.8c-0.2-0.08-0.4-0.16-0.59-0.24
			c-0.27-0.1-0.53-0.2-0.79-0.31C115.51,231.36,135.24,238.42,156.19,238.42"
        />
        <path
          fill="#D20F8C"
          d="M344.71,124.97c0,13.06-9.18,19.97-24.42,19.97h-30.67V75.65h30.2c13.92,0,22.44,6.15,22.44,19.12
			c0,6.91-4.73,12.21-9.66,13.82C337.99,109.83,344.71,115.32,344.71,124.97 M304.57,88.24v16.09h12.59
			c6.34,0,10.13-3.03,10.13-8.05c0-6.25-4.35-8.05-9.18-8.05H304.57z M329.56,124.12c0-5.3-3.6-8.62-10.03-8.62h-14.96v17.04h15.15
			C325.58,132.55,329.56,130.18,329.56,124.12"
        />
        <path
          fill="#D20F8C"
          d="M385.5,138.79c-3.22,4.54-9.18,7.1-16.47,7.1c-11.83,0-20.35-5.77-20.35-16.76
			c0-11.45,11.17-17.23,21.96-17.23c4.83,0,9.56,0.66,14.48,2.08c0.19-8.14-4.26-11.64-12.02-11.64c-7.19,0-14.77,2.27-18.08,3.88
			l-3.5-10.51c4.92-2.37,13.63-5.49,23.67-5.49c16.85,0,24.33,8.24,24.33,24.61v30.1h-13.92L385.5,138.79z M385.12,126.11v-3.6
			c-3.41-1.23-7.01-1.8-10.79-1.8c-6.15,0-11.27,2.56-11.27,7.38c0,4.92,4.54,7.48,10.03,7.48
			C379.44,135.58,385.12,132.45,385.12,126.11"
        />
        <path
          fill="#D20F8C"
          d="M460.05,113.24v31.71h-14.39v-29.54c0-7.86-4.64-12.21-11.45-12.21c-7.38,0-12.87,5.11-12.87,12.31v29.44
			h-14.39V91.27h14.39v8.05c4.35-4.45,10.6-9.09,18.84-9.09C451.91,90.23,460.05,98.85,460.05,113.24"
        />
        <path
          fill="#D20F8C"
          d="M493.48,90.23c12.21,0,21.11,6.72,24.71,15.15l-12.31,5.21c-2.18-3.98-5.96-7.38-12.31-7.38
			c-8.24,0-14.1,6.44-14.1,15.05c0,8.62,5.87,14.77,14.1,14.77c6.44,0,10.41-3.31,12.5-7.1l12.31,5.11
			c-3.69,8.8-12.31,14.96-24.8,14.96c-16,0-28.49-11.55-28.49-27.74C465.08,102.63,477.67,90.23,493.48,90.23"
        />
        <path
          fill="#D20F8C"
          d="M520.17,118.06c0-15.34,11.93-27.83,28.68-27.83c16.76,0,28.68,12.5,28.68,27.83s-11.93,27.93-28.68,27.93
			C532.1,145.99,520.17,133.4,520.17,118.06 M534.65,118.06c0,8.43,5.4,15.05,14.2,15.05c8.9,0,14.2-6.63,14.2-15.05
			c0-8.52-5.3-14.96-14.2-14.96C540.05,103.11,534.65,109.54,534.65,118.06"
        />
        <path
          fill="#D20F8C"
          d="M355.84,194.7c-1.7,17.04-15.15,30.2-35.78,30.2c-21.3,0-36.92-15.71-36.92-35.78
			c0-20.16,15.62-35.88,36.92-35.88c16.19,0,26.41,8.24,32.28,18.18l-13.44,6.25c-3.41-5.4-9.18-10.7-18.84-10.7
			c-12.4,0-21.96,9.56-21.96,22.15c0,12.5,9.75,21.96,21.96,21.96c10.32,0,18.27-5.58,19.79-14.67H315.7v-12.02h40.42
			C356.22,186.46,356.41,189.87,355.84,194.7"
        />
        <path
          fill="#D20F8C"
          d="M415.93,169.99v53.68h-14.39v-8.05c-4.35,4.45-10.6,9.09-18.84,9.09c-11.74,0-19.88-8.61-19.88-23v-31.71
			h14.39v29.54c0,7.86,4.64,12.21,11.45,12.21c7.38,0,12.87-5.11,12.87-12.31v-29.44H415.93z"
        />
        <path
          fill="#D20F8C"
          d="M458.56,217.51c-3.22,4.54-9.18,7.1-16.47,7.1c-11.83,0-20.35-5.77-20.35-16.76
			c0-11.45,11.17-17.23,21.96-17.23c4.83,0,9.56,0.66,14.48,2.08c0.19-8.14-4.26-11.64-12.02-11.64c-7.19,0-14.77,2.27-18.08,3.88
			l-3.5-10.51c4.92-2.37,13.63-5.49,23.67-5.49c16.85,0,24.33,8.24,24.33,24.61v30.1h-13.92L458.56,217.51z M458.18,204.82v-3.6
			c-3.41-1.23-7.01-1.8-10.79-1.8c-6.15,0-11.26,2.56-11.26,7.38c0,4.92,4.54,7.48,10.03,7.48
			C452.5,214.29,458.18,211.17,458.18,204.82"
        />
        <path
          fill="#D20F8C"
          d="M534.47,169.99l-24.14,57.65c-4.83,11.55-10.7,16.76-21.87,16.76c-5.96,0-10.13-1.14-15.62-3.79l4.54-11.45
			c2.75,1.23,5.68,2.56,9.66,2.56c5.21,0,7.86-2.56,10.41-8.61l0.1-0.28l-23.57-52.82h16.28l14.29,35.5l14.29-35.5H534.47z"
        />
        <path
          fill="#D20F8C"
          d="M571.89,217.51c-3.22,4.54-9.18,7.1-16.47,7.1c-11.83,0-20.35-5.77-20.35-16.76
			c0-11.45,11.17-17.23,21.96-17.23c4.83,0,9.56,0.66,14.48,2.08c0.19-8.14-4.26-11.64-12.02-11.64c-7.19,0-14.77,2.27-18.08,3.88
			l-3.5-10.51c4.92-2.37,13.63-5.49,23.67-5.49c16.85,0,24.33,8.24,24.33,24.61v30.1h-13.91L571.89,217.51z M571.51,204.82v-3.6
			c-3.41-1.23-7.01-1.8-10.79-1.8c-6.15,0-11.27,2.56-11.27,7.38c0,4.92,4.54,7.48,10.03,7.48
			C565.83,214.29,571.51,211.17,571.51,204.82"
        />
        <path
          fill="#D20F8C"
          d="M647.9,169.99v73.46h-14.3v-26.41c-3.6,4.54-9.18,7.67-17.89,7.67c-14.01,0-23.95-11.08-23.95-27.83
			c0-16.76,9.94-27.93,23.95-27.93c8.71,0,14.29,3.12,17.89,7.67v-6.63H647.9z M633.79,196.78c0-8.33-4.73-14.77-13.44-14.77
			c-8.71,0-14.01,6.44-14.01,14.77c0,8.52,5.3,14.86,14.01,14.86C629.06,211.64,633.79,205.3,633.79,196.78"
        />
        <path
          fill="#D20F8C"
          d="M708.82,169.99v53.68h-14.39v-8.05c-4.35,4.45-10.6,9.09-18.84,9.09c-11.74,0-19.88-8.61-19.88-23v-31.71
			h14.39v29.54c0,7.86,4.64,12.21,11.45,12.21c7.38,0,12.87-5.11,12.87-12.31v-29.44H708.82z"
        />
        <path
          fill="#D20F8C"
          d="M725.59,164.4c-4.64,0-8.33-3.6-8.33-7.95c0-4.16,3.79-7.76,8.33-7.76c4.45,0,8.14,3.41,8.14,7.76
			C733.73,160.81,730.13,164.4,725.59,164.4 M718.3,169.99h14.39v53.68H718.3V169.99z"
        />
        <polygon
          fill="#D20F8C"
          points="742.17,152.37 756.56,150.2 756.56,223.66 742.17,223.66"
        />
      </g>
    </g>
  </Icon>
);

export default BGLogoIcon;

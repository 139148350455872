export const APPLICATIONS = {
    PMA: "pma",
    APPS: "apps",
};

export const SERVER_URLS = {
    PMA_PASSWORDLESS: "https://api.jelou.ai/v1/auth/login/passwordless",
    APPS_SSO: "http://apps.jelou.ai/sso/",
    // PMA_PASSWORDLESS: "https://api2.01lab.co/v1/auth/login/passwordless",
    // APPS_SSO: "http://apps.01lab.co/sso/",
    PMA_IMPERSONATE: "https://pma.jelou.ai/impersonate/",
};
